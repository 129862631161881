import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Header from 'components/txjp/Header';
import Charts from 'components/txjp/Charts';
import History from 'components/txjp/History';

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Header />
      <Charts />
      <History />
    </Router>
  );
};

export default App;
