import React from 'react';
import PropTypes from 'prop-types';

const ChartsTable = ({ data }) => {
  const { name, value } = data;

  return (
    <tr>
      <td>{name}</td>
      <td className="text-end">{value}%</td>
    </tr>
  );
};

ChartsTable.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired
};

export default ChartsTable;
