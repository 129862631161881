import React from 'react';
import { Card, Table } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import ChartsChart from './ChartsChart';
import ChartsTable from './ChartsTable';
import useSWR from 'swr';

function fetcher(...urls) {
  const f = url => fetch(url).then(response => response.json());
  return Promise.all(urls.map(f));
}

const Charts = () => {
  const { data, error } = useSWR(['/data/getTopToken.json'], fetcher);
  if (error) return <div>failed to load</div>
  if (!data) return <div>loading...</div>

  return (
    <Card className="h-100">
      <FalconCardHeader
        title="Top 10 Token Holders ( TXJP and fTXJP ) without Contract Address [hour]"
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body
        as={Flex}
        direction="column"
        justifyContent="between"
        className="py-0"
      >
        <div className="my-auto py-5 py-md-5">
          <ChartsChart />
        </div>
        <div className="border-top">
          <Table size="sm" className="mb-0">
            <tbody>
              {data[0].map(item => (
                <ChartsTable key={item.name} data={item} />
              ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Charts;
