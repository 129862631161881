import React from 'react';
import {Card, Table} from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import useSWR from 'swr';

function shortenAddress(address: string, chars = 4) {
  if (address.startsWith('0x')) {
    return `${address.substring(0, chars + 2)}...${address.substring(address.length - chars)}`;
  } else {
    return address;
  }
}

function fetcher(...urls) {
  const f = url => fetch(url).then(response => response.json());
  return Promise.all(urls.map(f));
}

const TableRow = ({ data }) => {
  const { transactionHash, timestamp, from, to, value } =
    data;

  return (
    <tr className="align-middle">
      <td className="text-nowrap text-monospace">{shortenAddress(transactionHash)}</td>
      <td className="text-nowrap">{timestamp}</td>
      <td className="text-nowrap">{shortenAddress(from)}</td>
      <td className="text-nowrap">{shortenAddress(to)}</td>
      <td className="text-end">{value}</td>
    </tr>
  );
};

const ResponsiveTable = () => {
  const { data, error } = useSWR(['/data/getTokenHistory.json'], fetcher);
  if (error) return <div>failed to load</div>
  if (!data) return <div>loading...</div>

  return (
    <Table responsive striped hover>
      <thead>
        <tr>
          <th scope="col">transactionHash</th>
          <th scope="col">timestamp</th>
          <th scope="col">from</th>
          <th scope="col">to</th>
          <th scope="col">value</th>
        </tr>
      </thead>
      <tbody>
        {data[0].map(item => (
          <TableRow key={item.timestamp} data={item} />
        ))}
      </tbody>
    </Table>
  );
};

const History = () => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title="TXJP history latest 100"
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body
        as={Flex}
        direction="column"
        justifyContent="between"
        className="py-0"
      >
        <ResponsiveTable />
      </Card.Body>
    </Card>
  )
};

TableRow.propTypes = {
  data: PropTypes.shape({
    transactionHash: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired
};

export default History;
