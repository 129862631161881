import { React, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart, PieChart } from 'echarts/charts';
import useSWR from 'swr';

function fetcher(...urls) {
  const f = url => fetch(url).then(response => response.json());
  return Promise.all(urls.map(f));
}

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  PieChart,
  CanvasRenderer
]);

const ChartsChart = () => {
  const chartRef = useRef(null);

  const { data, error } = useSWR(['/data/getTopTokenHistory.json'], fetcher);
  if (error) return <div>failed to load</div>
  if (!data) return <div>loading...</div>

  const getOption = () => ({
    color: [
      getColor('danger'),
      getColor('warning'),
      getColor('info'),
      getColor('primary')
    ],
    legend: {
      top: 0,
      textStyle: {
        color: getColor('700')
      }
    },
    tooltip: {
      trigger: 'axis',
      showContent: false
    },
    dataset: {
      source: data[0]
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: getColor('300')
        }
      },
      axisLabel: {
        color: getColor('600')
      },
      axisPointer: {
        lineStyle: {
          color: getColor('300')
        }
      }
    },
    yAxis: {
      gridIndex: 0,
      axisLabel: {
        color: getColor('600')
      },
      splitLine: {
        lineStyle: {
          color: getColor('200')
        }
      }
    },
    series: [
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('danger'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('danger')
        },
        symbol: 'circle'
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('info'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('info')
        },
        symbol: 'circle'
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('warning'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('warning')
        },
        symbol: 'circle'
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('primary'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('primary')
        },
        symbol: 'circle'
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('danger'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('danger')
        },
        symbol: 'circle'
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('info'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('info')
        },
        symbol: 'circle'
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('warning'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('warning')
        },
        symbol: 'circle'
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('primary'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('primary')
        },
        symbol: 'circle'
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('danger'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('danger')
        },
        symbol: 'circle'
      },
      {
        type: 'line',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('info'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('info')
        },
        symbol: 'circle'
      },
      {
        type: 'pie',
        id: 'pie',
        radius: '30%',
        center: ['50%', '28%'],
        emphasis: { focus: 'data' },
        label: {
          formatter: '{b}: {@0} ({d}%)',
          color: getColor('600')
        },
        encode: {
          itemName: 'share',
          value: '0',
          tooltip: '0'
        }
      }
    ],
    grid: {
      right: 10,
      left: 5,
      bottom: 5,
      top: '55%',
      containLabel: true
    }
  });

  const updateAxisPointer = event => {
    var xAxisInfo = event.axesInfo[0];
    if (xAxisInfo) {
      var dimension = xAxisInfo.value + 1;

      chartRef.current.getEchartsInstance().setOption({
        series: {
          id: 'pie',
          label: {
            formatter: '{b}: {@[' + dimension + ']} ({d}%)'
          },
          encode: {
            value: dimension,
            tooltip: dimension
          }
        }
      });
    }
  };

  const onEvents = {
    updateAxisPointer: updateAxisPointer
  };
  return (
    <ReactECharts
      echarts={echarts}
      ref={chartRef}
      option={getOption()}
      style={{ height: '61.25rem' }}
      onEvents={onEvents}
    />
  )
};

export default ChartsChart;
