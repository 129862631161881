import React from 'react';
import PageHeader from 'components/common/PageHeader';
import useSWR from 'swr';

function fetcher(...urls) {
  const f = url => fetch(url).then(response => response.json());
  return Promise.all(urls.map(f));
}

const Header = () => {
  const { data, error } = useSWR(['/data/getTokenInfo.json'], fetcher);
  if (error) return <div>failed to load</div>
  if (!data) return <div>loading...</div>

  return (
    <PageHeader title="TXJP" className="mb-3">
      <p>{data[0].name}</p>
      <p>Total Supply: {data[0].totalSupply}</p>
      <p>Holders: {data[0].holdersCount}</p>
    </PageHeader>
  )
};

export default Header;
